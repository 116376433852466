import React from "react";

// reactstrap components
import {
	Row, Col, UncontrolledTooltip, Button, Input,
} from "reactstrap";
import store from 'store';
import dayjs from 'dayjs';
import DataTable from 'react-data-table-component';
//import { Rx, Gx } from "helper/ApiCaller.js";
import axios from 'axios';



class BrokerMembers extends React.Component {
	constructor(props) {
		super(props);

		//this.Rx = new Rx(this.props.history);
		//this.Gx = new Gx(this.props.history);

		this.claimColumns = [
			{
				name: 'Policy#',
				selector: 'policyNo',
				width:"240px",
				sortable: true,
				cell: row => {
					return row.policyNo ?? " -- "
				}
			},
			{
				name: 'Eff Date',
				selector: 'effDate',
				sortable: true,
				cell: row => dayjs(row.effDate).format('MM/DD/YYYY'),
			},
			{
				name: 'Term. Date',
				selector: 'termDate',
				sortable: true,
				cell: row => row.termDate ? dayjs(row.termDate).format('MM/DD/YYYY') : " -- ",
			},
			{
				name: 'Member No.',
				sortable: true,
				selector: 'memberNo',
				cell: row => {
					return row.memberNo ?? " -- "
				}
			},
			{
				name: 'Member Name',
				selector: 'name',
				sortable: true,
				cell: row => {
					return row.name ?? " -- "
				}
			},
			{
				name: 'Relation',
				sortable: true,
				selector: 'roles',
				cell: row => {
					

					return row.roles ?? " -- "
				}
			},
			{
				name: 'Email',
				sortable: true,
				selector: 'contacts.email.address',
				width:"240px",
				cell: row => {
					

					return row.contacts?.email?.address ?? " -- "
				}
			},
			{
				name: 'Claim Payment Method',
				sortable: true,
				selector: 'clPayMethod',
				width:"80px",
				cell: row => {
					
					//last two letter only
					return row.clPayMethod?.slice(-2)  ?? " -- "
				}
			},
			{
				name: 'ID Number',
				sortable: true,
				selector: 'idCardNo',
				cell: row => {
					
					return row.idCardNo?.replace(/.(?=.{3,}$)/g, "X")  ?? " -- "
				}
			},
			{
				name: 'Sex',
				width:"80px",
				selector: 'sex',
				cell: row => {
					

						return this.state.sysCodeList.find(s => s.codeType === "SEX" && s.refNo === row.sex)?.desc ?? " -- "
					
				},
				sortable: true,
			},
			{
				name: 'Schedule of Benedits',
				//width:"80px",
				selector: 'policyPlanDesc',
				cell: row => {
					return row.policyPlanDesc ?? " -- "

				},
				sortable: true,
			},
		];


		this.state = {
			memberList: [],
			sysCodeList: [],
			selectedItem: null,
			userList: [],
			userPosListEnabled: [],
			userPosListAvailable: [],
			userFilterTemp: "",
			userFilter: "",
			searchQurey: "",
			...this.props.match.params,

		};


		this.handleChange = this.handleChange.bind(this);
		this.handleTableChange = this.handleTableChange.bind(this);
		this.searchMembers = this.searchMembers.bind(this);
		this.getMembersSample = this.getMembersSample.bind(this);
		this.getMembersApi = this.getMembersApi.bind(this);

	}

	componentDidMount() {
		if (this.props.setPageTitle)
			this.props.setPageTitle("Members");

		this.props.isFetching();
		Promise.all([
			this.getJsonFile("/data/sysCode.json"),
		])
			.then(function ([sysCodeList]) {
				this.props.isFetching(false);
				this.setState({
					sysCodeList,
				}, () => {
					console.log("this.props.match.params",this.props.match.params)
					if (this.props.match.params.searchQurey && this.props.match.params.searchQurey !== "") {
						this.searchMembers();
					} 
				});
			}.bind(this));



	}


	searchMembers = async () => {
		this.props.isFetching();

		this.getMembersSample(this.state.searchQurey).then(async result => {
			return result
		}).then(result => {

			console.log(result)

			//let mergedResult = {data:result.flatMap(d=>[...d.data])}

			let memberList = result.data.map(row => {
				row.name = row.refProps.find(p => p.key === "nameShort")?.values[0] ?? ""
				row.idCardNo = row.refProps.find(p => p.key === "idCardNo")?.values[0] ?? ""
				row.memberNo = row.refProps.find(p => p.key === "memberNo")?.values[0] ?? ""
				row.policyNo = row.refProps.find(p => p.key === "memberPlan.policyPlan.policy.policyNo")?.values[1] ?? ""
				row.policyPlanDesc = row.refProps.find(p => p.key === "memberPlan.policyPlan.desc")?.values[1]  ?? ""
				return row
			})
			if (this.state.searchQurey !== "") {
				memberList = memberList
					.filter(row => {

						if (
							row.memberNo?.toUpperCase().includes(this.state.searchQurey?.toUpperCase().replace("-","")) ||
							row.idCardNo?.toUpperCase() === this.state.searchQurey?.toUpperCase() ||
							row.policyNo?.toUpperCase().includes(this.state.searchQurey?.toUpperCase()) ||
							row.name.toUpperCase().indexOf(this.state.searchQurey?.toUpperCase()) !== -1
						) {
							return true
						}
						return false
					})
			}
			this.props.isFetching(false);
			this.setState({
				memberList
			});
		});
		return;
		/*
				Promise.all([
					this.getMembers(`filter:"barcodeId=${this.state.searchQurey}"`),
					this.getMembers(`filter:"claimant={i}${this.state.searchQurey}"`),
					this.getMembers(`filter:"claimNo=${this.state.searchQurey}"`),
				])
					.then(function (claimSearchResults) {
		
						let memberList = claimSearchResults
										.flatMap(cl=>[...cl]) //merge
										.filter((cl,idx,self)=>(self.findIndex(s=>s._id === cl._id) === idx)); //distinct
						
		
						this.setState({
							memberList,
						});
						this.props.isFetching(false);
					}.bind(this));
					*/
	}




	getMembersSample = async ( searchQurey="") => {
		let result = [];

		result = await this.getMembersApi(searchQurey)
		console.log("result", result)
		return result;
	}

	getJsonFile = async (path) => {

		let result = [];

		await axios.get(path)
			.then(function (response) {
				//console.log(response.data);
				result = response.data;
			}).catch(function (e) {
				this.props.notify(e?.response?.data.message.toString() ?? e.toString());
			}.bind(this));


		return result;

	}

	getMembersApi = async (searchQurey) => {

		let user = store.get("user");

		console.log(user)
		let result = [];

		await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/members`, 
		{ ...user ,
			searchQurey
		}
		).then(data => {
			console.log(data)	
			result = data
		})


		

		return result;

	}

	onRowClicked = (row, event) => {
		console.log("onRowClicked", row, event);
		//this.props.history.push("./claims/view/" + row._id);
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}


	handleTableChange = (state) => {
		// You can use setState or dispatch with something like Redux so we can use the retrieved data
		console.log('Selected Rows: ', state.selectedRows);
	};

	render() {
		return (
			<>
				<div className="content pb-0 mt-2" >
					<Row>
						<Col md={"12"}>
							<Row className="rounded bg-light mb-2">
								<Col md="10" className="p-2">
									<Input type="text"
										className="my-2 h-75"
										value={this.state.searchQurey}
										name="searchQurey"
										onChange={this.handleChange}
										placeholder="Search: Member No./ ID Card# / Member Name / Policy No."
										onKeyPress={e => {
											if (e.key === 'Enter') {
												this.searchMembers();
											}
										}}
									/>
								</Col>
								<Col md="2" className="p-2">
									<Button className="my-2 h-75" color="success"
										onClick={this.searchMembers}
									>Search</Button>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<DataTable
								title="Members"
								columns={this.claimColumns}
								customStyles={{ rows: { style: { cursor: "pointer" } } }}
								data={this.state.memberList}
								//selectableRows // add for checkbox selection
								Clicked
								pagination
								striped
								highlightOnHover
								noContextMenu
								onSelectedRowsChange={this.handleTableChange}
								onRowClicked={this.onRowClicked}

							/>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default BrokerMembers;
